<template>
  <div class="question-wrapper">
    <h5>{{ question.question }}</h5>
    <div class="answers">
      <span>Extremely often</span>
      <div class="answers__inputs">
        <input class="main-radio" v-for="a in answers" :key="a" :value="a" type="radio" v-model="answer" />
      </div>
      <span>Not all Often</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultipleChoiceQuestion",
  props: { question: Object },
  data() {
    return {
      answers: Array.from({ length: 5 }, (_, i) => i + 1),
      answer: null,
    };
  },
  created() {
    if (this.question.answer) this.answer = this.question.answer;
  },
  watch: {
    answer(newAnswer) {
      this.$emit("changeAnswer", newAnswer);
    },
  },
};
</script>
<style scoped lang="scss">
.question-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;

  h5 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    color: white;
  }

  & .answers {
    display: flex;
    padding: 0 2.375rem;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 3.75rem;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(4px);
    font-weight: 700;
    font-size: 1.25rem;

    &__inputs {
      display: flex;
      gap: 59px;
    }
  }
}
</style>

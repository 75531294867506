<template>
  <section class="true-false-wrapper" v-if="currentQuestion">
    <h1>{{ currentQuestion.question }}</h1>
    <div class="options">
      <div v-for="(a, i) in currentQuestion.answer_options" :key="i" class="option" :class="answerIsCorrect(a.id)" @click="answer = a.id">
        <input class="main-radio" type="radio" v-model="answer" :value="a.id" :name="a.id" id="a.id" />
        <label :for="a.id">{{ a.answer_question }}</label>
      </div>
    </div>

    <p v-if="answerIsCorrect(answer) === 'wrong' && currentQuestion.explanation" class="explanation">
      {{currentQuestion.explanation}}
    </p>
  </section>
</template>

<script>
export default {
  props: {
    quiz: Object,
    currentQuestionIndex: { type: Number, default: 0 },
    checkingQuizAnswer: { type: Boolean, default: false },
  },
  data() {
    return {
      answer: null,
    };
  },
  created() {
    if (this.quiz.answer) this.answer = this.quiz.answer;
  },
  watch: {
    answer(newValue) {
      this.quiz.answer = newValue;
      this.$emit("canProceed", this.answer !== null && this.answer !== false);
    },
  },
  computed: {
    currentQuestion() {
      if (this.quiz.questions.length > this.currentQuestionIndex) {
        return this.quiz.questions[this.currentQuestionIndex];
      }
      
      return null;
    }
  },
  methods: {
    answerIsCorrect(answer_id) {
      if (this.checkingQuizAnswer) {
        if (answer_id == this.quiz.questions[this.currentQuestionIndex].correct_answer_id[0].id) {
          return "correct";
        }

        return "wrong";
      }

      return ""
    }
  }
};
</script>
<style scoped lang="scss">
.true-false-wrapper {
  margin-bottom: 4.375rem;

  h1 {
    font-weight: 500;
    font-size: 2.375rem;
    letter-spacing: 0.01em;
    color: white;
    margin-bottom: 2.875rem;
    text-align: center;
  }

  & .options {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    margin-bottom: 1.875rem;
    cursor: pointer;

    .option {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      padding: 0 1.25rem;
      height: 3.75rem;
      background-color: #7F5E9B;
      backdrop-filter: blur(4px);
      color: white;
      font-weight: 700;
      font-size: 1.25rem;

      &.wrong {
        background: #FC1F40;
      }
      &.correct {
        background: #1EB28D;
      }

      label {
        cursor: pointer;
      }
    }
  }

  & .explanation {
    color: #F53D48;
    font-weight: 400;
    font-size: 1.5rem;
  }
}
</style>

const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;
export default class MissionsService {
    static getUserCampaignsList() {
        return httpClient.post(`${baseApiUrl}/users/campaigns/all`);
    }

    static getUserCampaignDetail(campaign_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/campaigns/detail`, { campaign_id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static completeContentCampaign(campaign_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/campaigns/content/complete`, { campaign_id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static getContentDetail(content_id) {
        return new Promise((resolve, reject) => {
            return httpClient.get(`${baseApiUrl}/content/` + content_id)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static getUserContentCampaignsList() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/campaigns/content/list`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static getQuizz(quizz_id, campaign_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/campaigns/quizz/detail`, { quizz_id, campaign_id })
                .then((response) => {
                    // Randomize da ordem das respostas
                    response.data.questions.forEach(function (question) {
                        question.answer_options = question.answer_options
                            .map(value => ({ value, sort: Math.random() }))
                            .sort((a, b) => a.sort - b.sort)
                            .map(({ value }) => value);
                    });
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static getQuizzWithMulti(quizz_id, campaign_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/campaigns/quizz/detailwithmulti`, { quizz_id, campaign_id })
                .then((response) => {
                    // Randomize da ordem das respostas
                    response.data.questions.forEach(function (question) {
                        question.answer_options = question.answer_options
                            .map(value => ({ value, sort: Math.random() }))
                            .sort((a, b) => a.sort - b.sort)
                            .map(({ value }) => value);
                    });
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static checkQuizzAnswer(quizz_answer_option_id, campaign_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/campaigns/quizz/check/answer`, { quizz_answer_option_id, campaign_id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static checkQuizzMultiAnswer(quizz_answer_option_id, question_id, campaign_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/campaigns/quizz/check/multianswer`, { quizz_answer_option_id, question_id, campaign_id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static insertQuizzAnswers(quizz_id, quizz_answers, campaign_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/campaigns/quizz/answers`, { quizz_id, quizz_answers, campaign_id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static insertQuizzMultiAnswers(quizz_id, quizz_answers, campaign_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/campaigns/quizz/multianswers`, { quizz_id, quizz_answers, campaign_id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static insertFeedbackQuizzAnswers(quizz_id, quizz_answers, campaign_id) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/campaigns/feedback/answers`, { quizz_id, quizz_answers, campaign_id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[(_vm.quiz)?_c('div',{staticClass:"steps"},_vm._l((_vm.quiz.questions),function(q,i){return _c('figure',{key:i,staticClass:"step",class:{ active: i === _vm.currentQuestion }},[(i < _vm.quiz.questions.length - 1)?_c('div',{staticClass:"step__line"}):_vm._e(),(i === _vm.currentQuestion)?_c('img',{attrs:{"src":require("@/assets/images/content/check-green.svg"),"alt":""}}):_vm._e()])}),0):_vm._e(),(_vm.quiz)?_c('div',{staticClass:"quiz"},[(
                ['FEEDBACK', 'SURVEY', 'QUIZ', 'QUIZZ'].includes(
                    _vm.quiz.type
                ) &&
                _vm.quiz.questions.length > 0 &&
                _vm.quiz.questions[_vm.currentQuestion].type === 'SINGLE'
            )?_c('general-quiz',{ref:"quizComponent",attrs:{"quiz":_vm.quiz,"currentQuestionIndex":_vm.currentQuestion,"checkingQuizAnswer":_vm.checkingQuizAnswer},on:{"canProceed":function ($event) { return (_vm.canProceed = $event); }}}):_vm._e(),(
                ['FEEDBACK', 'SURVEY', 'QUIZ', 'QUIZZ'].includes(
                    _vm.quiz.type
                ) &&
                _vm.quiz.questions.length > 0 &&
                _vm.quiz.questions[_vm.currentQuestion].type === 'MULTI'
            )?_c('multiple-answers-quiz',{ref:"quizMultiComponent",attrs:{"quiz":_vm.quiz,"currentQuestionIndex":_vm.currentQuestion,"checkingQuizAnswer":_vm.checkingQuizAnswer},on:{"canProceed":function ($event) { return (_vm.canProceed = $event); }}}):_vm._e()],1):_vm._e(),_c('button',{staticClass:"main-btn next",attrs:{"disabled":!_vm.canProceed || _vm.loading},on:{"click":_vm.handleNext}},[(_vm.loading)?_c('span',[_vm._m(0)]):_c('span',[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader",staticStyle:{"width":"2em","height":"2em","margin":"0 auto"}},[_c('div')])}]

export { render, staticRenderFns }
<template>
  <section class="multiple-choice-wrapper">
    <h1>{{ quiz.mainQuestion }}</h1>

    <div class="questions">
      <question
        v-for="q in quiz.questions"
        :key="q.id"
        :question="q"
        @changeAnswer="($event) => changeAnswer(q.id, $event)"
      />
    </div>
  </section>
</template>

<script>
import question from "./question.vue";

export default {
  components: { question },
  props: {
    quiz: Object,
  },
  methods: {
    changeAnswer(questionId, answer) {
      this.quiz.questions.find((q) => q.id === questionId).answer = answer;
      this.$emit("canProceed", this.quiz.questions.filter((q) => q.answer === null).length === 0);
    },
  },
};
</script>
<style scoped lang="scss">
.multiple-choice-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 4.375rem;

  h1 {
    font-weight: 500;
    font-size: 2.375rem;
    letter-spacing: 0.01em;
    color: white;
    margin-bottom: 2.875rem;
    text-align: center;
  }

  .questions {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }
}
</style>

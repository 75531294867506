<template>
  <section class="quiz-ordering">
    <h1>{{ quiz.question }}</h1>

    <draggable
      class="answers"
      tag="ul"
      v-model="quiz.answers"
      @start="drag = true"
      @end="onEnd"
      :animation="200"
      :group="'description'"
      :disabled="false"
      :ghostClass="'ghost'"
    >
      <li v-for="a in quiz.answers" :key="a.order" class="answer">
        <div class="answer__order">{{ a.order }}</div>
        <span>{{ a.title }}</span>
      </li>
    </draggable>
  </section>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: { draggable },
  props: {
    quiz: Object,
  },
  data: () => ({
    drag: false,
  }),
  created() {
    // User can always go to the next quiz
    this.$emit("canProceed", true);
  },
  methods: {
    onEnd(e, i) {
      this.drag = false;

      const { answers } = this.quiz;
      const movedDown = e.newIndex > e.oldIndex;

      let posChanged = [];
      // If the user dragged the answer down, we need to move the answers above it down one position
      // If the user dragged the answer up, we need to move the answers below it up one position
      // To do that, we need to find all the answers that got moved by using the old and new index of the answer moved
      // The order in the answers starts at 1, so we need to subtract 1 to start at 0
      if (movedDown) {
        posChanged = answers.filter((a) => a.order - 1 >= e.oldIndex && a.order - 1 <= e.newIndex);
      } else {
        posChanged = answers.filter((a) => a.order - 1 >= e.newIndex && a.order - 1 <= e.oldIndex);
      }

      // After having all answers moved we just need to update the order of the answers using the position of the first answer in the array
      const firstPos = answers.indexOf(posChanged[0]);
      posChanged.forEach((a, i) => {
        a.order = firstPos + i + 1;
      });
    },
  },
  computed: {
    answers: function () {
      return this.quiz.answers.sort((a, b) => a.order - b.order);
    },
  },
};
</script>
<style scoped lang="scss">
.quiz-ordering {
  margin-bottom: 4.375rem;

  h1 {
    font-weight: 500;
    font-size: 2.375rem;
    letter-spacing: 0.01em;
    color: white;
    margin-bottom: 2.875rem;
    text-align: center;
  }

  .answers {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;

    .answer {
      padding: 0 1.25rem;
      display: flex;
      align-items: center;
      height: 3.75rem;
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(4px);
      font-weight: 700;
      font-size: 1.25rem;
      color: white;
      gap: 1.25rem;
      cursor: move;

      &__order {
        display: grid;
        place-content: center;
        width: 3.375rem;
        height: 2.8125rem;
        background-color: white;
        color: black;
      }
    }
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>

<template>
  <section class="true-false-wrapper" v-if="currentQuestion">
    <h1>{{ currentQuestion.question }}</h1>
    <div class="options">
      <label
        v-for="(a, i) in currentQuestion.answer_options"
        :key="i"
        class="option"
        :for="a.id"
        :class="[answerIsCorrect(a.id), {'cursor-pointer': !checkingQuizAnswer}]"
      >
        <input
          class="main-radio"
          :class="{'cursor-default': checkingQuizAnswer}"
          type="checkbox"
          :value="a.id"
          :name="a.id"
          :id="a.id"
          :disabled="checkingQuizAnswer"
          :checked="answers.includes(a.id)"
          @change="toggleAnswer(a.id)"
        />
        <span>{{ a.answer_question }}</span>
      </label>
    </div>

    <p
      v-if="
        answersAreCorrect === 'wrong' && currentQuestion.explanation
      "
      class="explanation"
    >
      {{ currentQuestion.explanation }}
    </p>
  </section>
</template>

<script>
export default {
  props: {
    quiz: Object,
    currentQuestionIndex: { type: Number, default: 0 },
    checkingQuizAnswer: { type: Boolean, default: false },
  },
  data() {
    return {
      answers: [],
    };
  },
  created() {
    if (this.quiz.answers) this.answers = this.quiz.answers;
  },
  watch: {
    answers(newValue) {
      // this.quiz.answers = newValue;
      this.$emit("canProceed", this.answers?.length > 0 && this.answers !== false);
    },
  },
  computed: {
    currentQuestion() {
      if (this.quiz.questions.length > this.currentQuestionIndex) {
        return this.quiz.questions[this.currentQuestionIndex];
      }

      return null;
    },
    answersAreCorrect() {
      if (this.checkingQuizAnswer) {
        const correct_answers = this.quiz.questions[
          this.currentQuestionIndex
        ].correct_answer_id.map((q) => {
          return q.id;
        });

        if (correct_answers.sort().join(",") === this.answers.sort().join(",")) {
          return "correct";
        }

        return "wrong";
      }

      return "";
    },
  },
  methods: {
    toggleAnswer(answer_id) {
      if (this.answers.includes(answer_id)) {
        var index = this.answers.indexOf(answer_id);
        if (index !== -1) {
          this.answers.splice(index, 1);
        }
      } else {
        this.answers.push(answer_id);
      }
    },
    answerIsCorrect(answer_id) {
      if (this.checkingQuizAnswer) {
        const correct_answers = this.quiz.questions[
          this.currentQuestionIndex
        ].correct_answer_id.map((q) => {
          return q.id;
        });

        if (correct_answers.includes(answer_id) && this.answers.includes(answer_id)) {
          return "correct";
        } else if (correct_answers.includes(answer_id) && !this.answers.includes(answer_id)) {
          return "correct";
        }

        return "wrong";
      }

      return "";
    },
  },
};
</script>
<style scoped lang="scss">
.true-false-wrapper {
  margin-bottom: 4.375rem;

  h1 {
    font-weight: 500;
    font-size: 2.375rem;
    letter-spacing: 0.01em;
    color: white;
    margin-bottom: 2.875rem;
    text-align: center;
  }

  & .options {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    margin-bottom: 1.875rem;

    .option {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      padding: 0 1.25rem;
      height: 3.75rem;
      background-color: #7f5e9b;
      backdrop-filter: blur(4px);
      color: white;
      font-weight: 700;
      font-size: 1.25rem;

      &.wrong {
        background: #fc1f40;
      }
      &.correct {
        background: #1eb28d;
      }
    }
  }

  & .explanation {
    color: #f53d48;
    font-weight: 400;
    font-size: 1.5rem;
  }
}

.cursor-default {
  cursor: default;
}
</style>

<template>
    <div class="wrapper">
        <div class="steps" v-if="quiz">
            <figure
                class="step"
                v-for="(q, i) in quiz.questions"
                :key="i"
                :class="{ active: i === currentQuestion }"
            >
                <div v-if="i < quiz.questions.length - 1" class="step__line" />
                <img
                    v-if="i === currentQuestion"
                    src="@/assets/images/content/check-green.svg"
                    alt=""
                />
            </figure>
        </div>
        <div class="quiz" v-if="quiz">
            <!-- <multiple-choice-quiz
        v-if="currentQuiz.type === 'multiple-choice'"
        :quiz="currentQuiz"
        @canProceed="($event) => (canProceed = $event)"
      /> -->

            <!-- <true-false-quiz
        v-if="quizz.type === 'true-false'"
        :quiz="this.currentQuiz"
        @canProceed="($event) => (canProceed = $event)"
      /> -->

            <!-- <general-quiz
        v-if="['FEEDBACK', 'SURVEY', 'QUIZ', 'QUIZZ'].includes(quiz.type)"
        :quiz="quiz"
        :currentQuestionIndex="currentQuestion"
        :checkingQuizAnswer="checkingQuizAnswer"
        @canProceed="($event) => (canProceed = $event)"
        ref="quizComponent"
      /> -->

            <!-- <ordering-quiz
        v-if="currentQuiz.type === 'ordering'"
        :quiz="this.currentQuiz"
        @canProceed="($event) => (canProceed = $event)"
      />

      <survey-quiz
        v-if="currentQuiz.type === 'survey'"
        :quiz="this.currentQuiz"
        @canProceed="($event) => (canProceed = $event)"
      />

      <write-word-quiz
        v-if="currentQuiz.type === 'write-word'"
        :quiz="this.currentQuiz"
        @canProceed="($event) => (canProceed = $event)"
      /> -->
            <general-quiz
                v-if="
                    ['FEEDBACK', 'SURVEY', 'QUIZ', 'QUIZZ'].includes(
                        quiz.type
                    ) &&
                    quiz.questions.length > 0 &&
                    quiz.questions[currentQuestion].type === 'SINGLE'
                "
                :quiz="quiz"
                :currentQuestionIndex="currentQuestion"
                :checkingQuizAnswer="checkingQuizAnswer"
                @canProceed="($event) => (canProceed = $event)"
                ref="quizComponent"
            />

            <multiple-answers-quiz
                v-if="
                    ['FEEDBACK', 'SURVEY', 'QUIZ', 'QUIZZ'].includes(
                        quiz.type
                    ) &&
                    quiz.questions.length > 0 &&
                    quiz.questions[currentQuestion].type === 'MULTI'
                "
                :quiz="quiz"
                :currentQuestionIndex="currentQuestion"
                :checkingQuizAnswer="checkingQuizAnswer"
                @canProceed="($event) => (canProceed = $event)"
                ref="quizMultiComponent"
            />

            <!-- <multiple-choice-quiz
        v-if="['FEEDBACK', 'SURVEY', 'QUIZ', 'QUIZZ'].includes(quiz.type)"
        :quiz="quiz"
        :currentQuestionIndex="currentQuestion"
        :checkingQuizAnswer="checkingQuizAnswer"
        @canProceed="($event) => (canProceed = $event)"
        ref="multiQuizComponent"
      /> -->
        </div>

        <button
            class="main-btn next"
            @click="handleNext"
            :disabled="!canProceed || loading"
        >
            <span v-if="loading">
                <div
                    class="loader"
                    style="width: 2em; height: 2em; margin: 0 auto"
                >
                    <div></div>
                </div>
            </span>
            <span v-else>
                {{ buttonText }}
            </span>
        </button>
    </div>
</template>

<script>
import MultipleChoiceQuiz from "../../components/Quizzes/MultipleChoiceQuiz/index.vue";
import OrderingQuiz from "../../components/Quizzes/OrderingQuiz.vue";
import SurveyQuiz from "../../components/Quizzes/SurveyQuiz.vue";
import GeneralQuiz from "../../components/Quizzes/GeneralQuiz.vue";
import MultipleAnswersQuiz from "../../components/Quizzes/MultipleAnswersQuiz.vue";
import TrueFalseQuiz from "../../components/Quizzes/TrueFalseQuiz.vue";
import WriteWordQuiz from "../../components/Quizzes/WriteWordQuiz.vue";

import MissionsService from "@/services/missions.service";

export default {
    components: {
        MultipleChoiceQuiz,
        TrueFalseQuiz,
        OrderingQuiz,
        OrderingQuiz,
        SurveyQuiz,
        GeneralQuiz,
        MultipleAnswersQuiz,
        WriteWordQuiz,
    },
    props: {
        quizz_id: {
            required: true,
        },
        campaign_id: {
            type: Number,
            required: false,
        },
        review_mode: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            currentQuiz: null,
            quiz: null,
            currentQuestion: null,
            canProceed: false,
            checkingQuizAnswer: false,
            quizAnswers: [],
            loading: false,
        };
    },
    created() {
        this.getQuizzDetails();
    },
    methods: {
        handleNext() {
            if (this.loading || !this.canProceed) {
                return;
            }

            this.loading = true;

            // const currentQuizIndex = this.quizzes.indexOf(this.currentQuiz);
            // if (currentQuizIndex < this.quizzes.length - 1) {
            //   this.currentQuiz = this.quizzes[currentQuizIndex + 1];
            // }

            if (
                this.currentQuestion < this.quiz.questions.length &&
                !this.checkingQuizAnswer
            ) {
                this.checkingQuizAnswer = true;

                if (
                    this.quiz.questions[this.currentQuestion].type === "MULTI"
                ) {
                    this.quizAnswers.push({
                        quizz_question_id:
                            this.quiz.questions[this.currentQuestion].id,
                        value: this.$refs.quizMultiComponent.answers,
                    });
                } else {
                    this.quizAnswers.push({
                        quizz_question_id:
                            this.quiz.questions[this.currentQuestion].id,
                        value: [this.$refs.quizComponent.answer],
                    });
                }

                const startedQuizz = {
                    id: this.quiz.id,
                    answers: this.quizAnswers,
                    currentQuestion: this.currentQuestion + 1,
                };

                localStorage.setItem(
                    "started_quizz",
                    JSON.stringify(startedQuizz)
                );

                // this.checkQuizzAnswer(this.$refs.quizComponent.answer);
            } else if (
                this.currentQuestion < this.quiz.questions.length &&
                this.checkingQuizAnswer
            ) {
                // Go to next question
                if (this.currentQuestion >= this.quiz.questions.length - 1) {
                    // Finished quiz
                    this.submitQuiz();
                    return false;
                }

                this.checkingQuizAnswer = false;
                if (this.$refs.quizComponent) {
                    this.$refs.quizComponent.answer = false;
                }

                if (this.$refs.quizMultiComponent) {
                    this.$refs.quizMultiComponent.answers = [];
                }

                this.canProceed = false;
                this.currentQuestion++;
            } else {
                // Finished quizz
                // @TODO Launch mission success popup
                this.canProceed = false;
            }

            this.loading = false;
        },
        getQuizzDetails() {
            MissionsService.getQuizzWithMulti(this.quizz_id, this.campaign_id)
                .then((resp) => {
                    if (resp == null) {
                        this.goToMissions();
                        this.$noty.error("O quizz não foi encontrado.");
                    }

                    this.currentQuestion = 0;
                    this.quiz = resp;

                    if (localStorage.getItem("started_quizz")) {
                        const startedQuizz = JSON.parse(
                            localStorage.getItem("started_quizz")
                        );

                        if (this.quiz.id === startedQuizz.id) {
                            this.currentQuestion = startedQuizz.currentQuestion;
                            this.quizAnswers = startedQuizz.answers;
                        }
                    }
                })
                .catch((err) => {
                    this.goToMissions();
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        checkQuizzAnswer(answer_id) {
            //@TODO(Nuno) is this needed? The correct_answer_id already comes with the quizz detail
            if (this.quiz.questions[this.currentQuestion].type === "MULTI") {
                MissionsService.checkQuizzMultiAnswer(
                    answer_id,
                    this.campaign_id
                )
                    .then((resp) => {
                        // this.currentQuestion = 0;
                    })
                    .catch((err) => {
                        this.$noty.error(err.message);
                    })
                    .finally(() => (this.loading = false));
            } else {
                MissionsService.checkQuizzAnswer(answer_id, this.campaign_id)
                    .then((resp) => {
                        // this.currentQuestion = 0;
                    })
                    .catch((err) => {
                        this.$noty.error(err.message);
                    })
                    .finally(() => (this.loading = false));
            }
        },
        submitQuiz() {
            MissionsService.insertQuizzMultiAnswers(
                this.quizz_id,
                this.quizAnswers,
                this.campaign_id
            )
                .then((resp) => {
                    this.quiz = null;

                    this.$store.dispatch(
                        "user/updateUserPoints",
                        resp.user_points
                    );

                    // @TODO Launch mission success popup
                    this.goToMissions();
                    this.$noty.success("Quiz terminado!");

                    localStorage.removeItem("started_quizz");
                })
                .catch((err) => {
                    this.goToMissions();
                    this.$noty.error(err.message);
                })
                .finally(() => (this.loading = false));
        },
        goToMissions() {
            this.$root.handleInternalNavigationTo("Missions");
        },
    },
    computed: {
        buttonText() {
            // const currentQuizIndex = this.quizzes.indexOf(this.currentQuiz);
            // if (currentQuizIndex === this.quizzes.length - 1) return "Submeter";

            return "Seguinte";
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2.1875rem 25.6875rem;

    & .steps {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6.5625rem;

        & .step {
            width: 1.9375rem;
            height: 1.9375rem;
            position: relative;
            background-color: #007182;
            display: flex;
            padding: 0.375rem;
            margin-bottom: 4.75rem;

            & > img {
                z-index: 2;
            }

            &__line {
                width: 8.5rem;
                height: 0.375rem;
                background-color: #007182;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                z-index: 0;
            }

            &.active::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 3.3125rem;
                height: 3.3125rem;
                background-color: $main-color;
                z-index: -1;
            }
        }
    }

    .quiz {
        width: 69.4375rem;
    }
}

.next {
    width: 25rem;
    align-self: center;
}
</style>

<template>
  <section class="survey-quiz">
    <h1>{{ quiz.mainQuestion }}</h1>
    <div class="options">
      <div v-for="q in quiz.questions" :key="q.id" class="option" @click="q.answer = !q.answer">
        <input class="main-radio" type="checkbox" v-model="q.answer" />
        <label for="true">{{ q.title }}</label>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    quiz: Object,
  },
  created() {
    // User can always go to the next quiz
    this.$emit("canProceed", true);
  },
};
</script>

<style scoped lang="scss">
.survey-quiz {
  margin-bottom: 4.375rem;

  h1 {
    font-weight: 500;
    font-size: 2.375rem;
    letter-spacing: 0.01em;
    color: white;
    margin-bottom: 2.875rem;
    text-align: center;
  }

  & .options {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    cursor: pointer;

    .option {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      padding: 0 1.25rem;
      height: 3.75rem;
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(4px);
      color: white;
      font-weight: 700;
      font-size: 1.25rem;

      label {
        cursor: pointer;
      }
    }
  }
}
</style>

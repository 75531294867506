<template>
  <section class="write-word-quiz">
    <h1>{{ quiz.question }}</h1>

    <div class="answers">
      <div class="answer-wrapper">
        <div class="answer">
          <input type="text" value="Txt" />
        </div>
      </div>

      <div class="answer-wrapper">
        <div class="answer">
          <input type="text" class="error" value="Txt" />
        </div>
        <span class="error-message">Errado. (Palavra Correta)</span>
      </div>
    </div>
  </section>
</template>

<script>
// I don't know how to implement this one, the layout itself doesn't make sense to me.
// Without the endpoint I don't know how it's supposed to work
export default {
  props: {
    quiz: Object,
  },
};
</script>
<style scoped lang="scss">
.write-word-quiz {
  margin-bottom: 4.375rem;
  h1 {
    font-weight: 500;
    font-size: 2.375rem;
    letter-spacing: 0.01em;
    color: white;
    margin-bottom: 2.875rem;
    text-align: center;
  }

  .answers {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;

    .answer-wrapper {
      .answer {
        display: flex;
        align-items: center;
        padding: 2.0625rem 1.75rem;
        height: 6.875rem;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(4px);
        margin-bottom: 1.875rem;

        & > input {
          flex: 1;
          border: 0.0625rem solid white;
          border-radius: 0.25rem;
          background-color: transparent;
          color: white;
          font-weight: 700;
          font-size: 1.25rem;
          padding: 0.5rem 0.625rem;

          &.error {
            border: 0.0625rem solid #f53d48;
          }
        }
      }

      .error-message {
        color: #f53d48;
        font-weight: 400;
        font-size: 1.5rem;
      }
    }
  }
}
</style>

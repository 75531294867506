<template>
  <section class="true-false-wrapper">
    <h1>{{ quiz.question }}</h1>
    <div class="options">
      <div class="option" @click="answer = true">
        <input class="main-radio" type="radio" v-model="answer" :value="true" name="true" id="true" />
        <label for="true">Verdadeiro</label>
      </div>
      <div class="option" @click="answer = false">
        <input class="main-radio" type="radio" v-model="answer" :value="false" name="false" id="false" />
        <label for="false">Falso</label>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    quiz: Object,
  },
  data() {
    return {
      answer: null,
    };
  },
  created() {
    if (this.quiz.answer) this.answer = this.quiz.answer;
  },
  watch: {
    answer(newValue) {
      this.quiz.answer = newValue;
      this.$emit("canProceed", this.answer !== null);
    },
  },
};
</script>
<style scoped lang="scss">
.true-false-wrapper {
  margin-bottom: 4.375rem;

  h1 {
    font-weight: 500;
    font-size: 2.375rem;
    letter-spacing: 0.01em;
    color: white;
    margin-bottom: 2.875rem;
    text-align: center;
  }

  & .options {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    cursor: pointer;

    .option {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      padding: 0 1.25rem;
      height: 3.75rem;
      background-color: #7F5E9B;
      backdrop-filter: blur(4px);
      color: white;
      font-weight: 700;
      font-size: 1.25rem;

      label {
        cursor: pointer;
      }
    }
  }
}
</style>
